import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MediaCard from "@quantmind/Components/Card";
import ContactUs from "@quantmind/Components/ContactUs";
import Section from "@quantmind/Components/Section";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";

const image = "unsplash-hpjSkU2UYSU";

export const WebUI: React.FC = () => (
  <MediaCard title="Web UI" image={image} url="/web-ui">
    We design dynamic web applications using several modern front-end libraries
    including React, d3. We integrate with any back-end API, Rest, GRPC or
    GraphQL
  </MediaCard>
);

const WebUIPage: React.FC = () => {
  const sx = { pt: 4, pb: 2 };
  const theme = useTheme();
  const serviceTitleColor = theme.palette.warning.main;
  return (
    <WebPage
      title="Web UI"
      htmlTitle="Quantmind provides web UI and dashboard development services"
      heroImage={image}
      description="We develop web UI and dashboard solutions for hedge funds and proprietary trading firms"
      hero_dark={true}
      filter={0.8}
    >
      <Section maxWidth="md" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          sx={{ pb: 4 }}
        >
          A well-designed user interface is a crucial aspect of any successful
          software application. At Quantmind, we specialize in creating stunning
          and functional UI experiences that captivate users and enhance the
          overall usability of applications.
        </Typography>
      </Section>
      <Section maxWidth="md" title="Data Visualizations" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Our mastery in data visualization allows us to create real-time charts
          and graphs that not only convey complex data but also empower users to
          derive actionable insights.
        </Typography>
      </Section>
      <Section maxWidth="sm" title="Get Started" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Ready to transform your UI experience? Reach out to us today to
          discuss your project requirements.
        </Typography>
        <Typography align="center" sx={{ p: 2 }}>
          <ContactUs />
        </Typography>
      </Section>
    </WebPage>
  );
};

export default WebUIPage;
