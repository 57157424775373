import { Page, Parallax, useImage } from "@metablock/react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import Header from "@quantmind/Components/Header";
import logos from "@quantmind/Components/logos";
import { darkTheme, lightTheme } from "@quantmind/theme";
import React from "react";
import { useLocation } from "react-router-dom";

interface WebPageProps {
  title: any;
  titleVariant?: any;
  description: string;
  descriptionVariant?: any;
  heroImage?: string;
  logo?: string | null;
  htmlTitle?: string;
  children?: any;
  heroMaxHeight?: string;
  hero_dark?: boolean;
  titleSx?: any;
  [x: string]: any;
}

const WebPage = (props: WebPageProps) => {
  const {
    children,
    title,
    description,
    prefix = true,
    htmlTitle,
    heroMaxHeight = "500px",
    titleVariant = "h2",
    descriptionVariant = "h6",
    changeColorHeight = 50,
    minScrollWidth = 0,
    hero_dark,
    hero_color,
    image = logos.card,
    logo,
    titleSx,
    heroImage,
    ...extra
  } = props;
  const theme = useTheme();
  const colorLight = theme.palette.common.white;
  const hero_page_color = hero_color ? hero_color : "textPrimary";
  const location = useLocation();
  const img = useImage(heroImage || "");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const titleTopoSx = {
    margin: "1.75rem 0 0.875rem",
    fontWeight: 700,
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  };
  const changeColorOnScroll = {
    height: changeColorHeight,
    color: colorLight,
    backgroundColor: "neutral",
  };
  const defaultLogo =
    logo === undefined ? (hero_dark ? logos.light : logos.dark) : logo;
  const lightLogo = logos.light;
  const tSx = { p: 1, ...titleSx };
  return (
    <Page
      title={htmlTitle || title}
      prefix={prefix}
      description={description}
      image={image}
      twitter_card="summary_large_image"
    >
      <ThemeProvider theme={hero_dark ? darkTheme : lightTheme}>
        <Header
          logo={defaultLogo}
          logoChange={lightLogo}
          backgroundColor="transparent"
          changeColorOnScroll={changeColorOnScroll}
        />
        <Parallax
          {...extra}
          urls={img.urls}
          speed={1}
          maxHeight={heroMaxHeight}
          minScrollWidth={minScrollWidth}
        >
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} md={8}>
                <Box sx={tSx}>
                  <Typography
                    color={hero_page_color}
                    variant={titleVariant}
                    component="h1"
                    sx={titleTopoSx}
                  >
                    {title}
                  </Typography>
                  <Typography
                    color={hero_page_color}
                    variant={descriptionVariant}
                    component="p"
                  >
                    {description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Parallax>
      </ThemeProvider>
      <Box component="main">{children}</Box>
    </Page>
  );
};

export default WebPage;
