import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CmsRoute, NotFound } from "@metablock/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import blog from "../contents/blog/collection.json";
import site from "../contents/site/collection.json";
import Footer from "./Components/Footer";
import About from "./Pages/About";
import CmsPageEntry from "./Pages/BlogEntry";
import BlogList from "./Pages/BlogList";
import Contact from "./Pages/Contact";
import Expertise from "./Pages/Expertise";
import Home from "./Pages/LandingPage";
import OSS from "./Pages/OSS";
import Python from "./Pages/Python";
import Rust from "./Pages/Rust";
import Services from "./Pages/Services";
import TeamAugmentation from "./Pages/TeamAugmentation";
import Trading from "./Pages/TradingApplications";
import WebUI from "./Pages/WebUI";
import { darkTheme } from "./theme";

const emotionCache = createCache({
  key: "emotion-cache-no-speedy",
  speedy: false,
});

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <CacheProvider value={emotionCache}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/expertise" element={<Expertise />} />
            <Route path="/python" element={<Python />} />
            <Route path="/rust" element={<Rust />} />
            <Route path="/trading" element={<Trading />} />
            <Route path="/services" element={<Services />} />
            <Route path="/oss" element={<OSS />} />
            <Route path="/web-ui" element={<WebUI />} />
            <Route path="/team-augmentation" element={<TeamAugmentation />} />
            <Route
              path="/blog/*"
              element={
                <CmsRoute
                  topic="blog"
                  slug={blog.slug}
                  ListComponent={BlogList}
                  EntryComponent={CmsPageEntry}
                />
              }
            />
            <Route
              path="*"
              element={
                <CmsRoute
                  topic="site"
                  slug={site.slug}
                  ListComponent={false}
                  EntryComponent={CmsPageEntry}
                />
              }
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <Footer />
        </CacheProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
