import Grid from "@mui/material/Grid";
import Section from "@quantmind/Components/Section";
import { Expertise } from "@quantmind/Pages/Expertise";
import { Services } from "@quantmind/Pages/Services";
import React from "react";

const ProductSection: React.FC = () => {
  const grid = { xs: 12, sm: 6 };
  return (
    <Section>
      <Grid container spacing={5}>
        <Grid item {...grid}>
          <Services />
        </Grid>
        <Grid item {...grid}>
          <Expertise />
        </Grid>
      </Grid>
    </Section>
  );
};

export default ProductSection;
