import { Link } from "@metablock/react";
import TailoredIcon from "@mui/icons-material/Assignment";
import GroupsIcon from "@mui/icons-material/Diversity3";
import ExpertIcon from "@mui/icons-material/School";
import { Typography } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import MediaCard from "@quantmind/Components/Card";
import ContactUs from "@quantmind/Components/ContactUs";
import Section from "@quantmind/Components/Section";
import WebPage from "@quantmind/Views/WebPage";
import { cardElevation, cardRatio } from "@quantmind/theme";
import React from "react";

const image = "unsplash-7de474KZIbs";
const whyUs = [
  {
    title: "WebUI Experts",
    icon: <ExpertIcon />,
    description:
      "Our team consists of experienced WebUI developers who are" +
      " passionate about pushing the boundaries of what's possible with the language.",
  },
  {
    title: "Tailored Solutions",
    icon: <TailoredIcon />,
    description:
      "We understand that every project is unique. Our consulting services are customized to fit your specific requirements and goals.",
  },
  {
    title: "Collaborative Approach",
    icon: <GroupsIcon />,
    description:
      "We believe in working closely with our clients throughout the" +
      " development process, ensuring that your vision is realized at every step.",
  },
];

export const TeamAugmentation: React.FC = () => (
  <MediaCard
    elevation={cardElevation}
    ratio={cardRatio}
    title="Team Augmentation"
    image={image}
    url="/team-augmentation"
    opacity={0.5}
  >
    We provide strategy and consulting services to businesses eager to
    understand, adopt, and succeed with new technology solutions. When you hire
    us, we become members of your development team with CTO-level expertise.
  </MediaCard>
);

const TeamAugmentationPage: React.FC = () => {
  const sx = { pt: 4, pb: 2 };
  const theme = useTheme();
  const serviceTitleColor = theme.palette.warning.main;
  return (
    <WebPage
      title="Team Augmentation"
      htmlTitle="Quantmind provides team augmentation services"
      heroImage={image}
      description="We can help you overcome blockers caused by a lack of in-house expertise"
      hero_dark={true}
      filter={0.8}
    >
      <Section maxWidth="sm" sx={sx}>
        <Typography
          color="textSecondary"
          variant="h5"
          component="h2"
          align="center"
          sx={{
            mb: 4,
            p: 3,
            borderRadius: 5,
            color: "black",
            fontWeight: 700,
            backgroundColor: deepOrange[500],
          }}
        >
          Accelerate your growth with dedicated specialists
        </Typography>
      </Section>
      <Section maxWidth="md" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          sx={{ pb: 4 }}
        >
          Are you looking to accelerate your software development projects? Our
          expert team at Quantmind offers specialized team augmentation services
          that can seamlessly integrate with your in-house development
          processes. With a strong focus on <Link to="/rust">Rust</Link> and{" "}
          <Link to="/python">Python</Link> programming languages, we specialize
          in crafting cutting-edge solutions for trading algorithm software,
          quantitative finance, and data analysis.
        </Typography>
      </Section>
      <Section maxWidth="md" title="Software Development" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Our developers can contribute to your projects by writing clean,
          maintainable code that aligns with industry best practices. Whether
          it's building trading algorithms, financial models, or data analysis
          tools, we bring our technical excellence to every line of code we
          write.
        </Typography>
      </Section>
      <Section maxWidth="md" title="Code Review and Quality Assurance" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Ensuring the quality and reliability of your software is paramount.
          Our team can conduct thorough code reviews, identify potential issues,
          and suggest improvements to enhance the overall quality of your
          applications.
        </Typography>
      </Section>
      <Section maxWidth="md" title="Technical Consultation" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          If you're seeking guidance on architectural decisions, technology
          stack selection, or overall project strategy, our experts are here to
          provide valuable insights and recommendations based on years of
          experience.
        </Typography>
      </Section>
      <Section
        maxWidth="md"
        title="Seamless Integration with Your Team"
        sx={sx}
      >
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          We understand the importance of collaboration and seamless integration
          when augmenting your development team. Our professionals are adept at
          adapting to your workflows, tools, and communication channels. This
          ensures a harmonious working relationship that maximizes productivity
          and minimizes any disruption to your existing processes.
        </Typography>
      </Section>
      <Section
        maxWidth="sm"
        title="Ready to Elevate Your Software Projects?"
        sx={sx}
      >
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          At Quantmind, we're dedicated to empowering businesses with top-tier
          software solutions in the fields of trading algorithms, quantitative
          finance, and data analysis. Our team augmentation services are
          designed to seamlessly integrate with your goals and deliver
          exceptional results. Contact us today to learn more about how we can
          collaborate on your next project.
        </Typography>
        <Typography align="center" sx={{ p: 2 }}>
          <ContactUs />
        </Typography>
      </Section>
    </WebPage>
  );
};

export default TeamAugmentationPage;
