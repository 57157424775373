import { assetUrl } from "@metablock/core";
import { Box, Typography } from "@mui/material";
import MediaCard from "@quantmind/Components/Card";
import ContactUs from "@quantmind/Components/ContactUs";
import Section from "@quantmind/Components/Section";
import WebPage from "@quantmind/Views/WebPage";
import { cardElevation, cardRatio } from "@quantmind/theme";
import React from "react";

const image = "unsplash-fiXLQXAhCfk";

export const TradingApplications: React.FC = () => (
  <MediaCard
    elevation={cardElevation}
    title="Trading Applications"
    image={image}
    ratio={cardRatio}
    url="/trading"
  >
    We are expert in building trading applications for executing systematic
    strategies in financial market and sport trading. We device algorithms as
    well as the infrastructure.
  </MediaCard>
);

const Process: React.FC = () => (
  <MediaCard
    elevation={cardElevation}
    title="Our Process"
    image={assetUrl("quantmind/process.svg")}
  >
    We build properly tested software, in short increments with constant
    feedback with our clients. Our Agile development process provides
    opportunities to assess directions throughout the development lifecycle.
  </MediaCard>
);

const Cloud: React.FC = () => (
  <MediaCard
    elevation={cardElevation}
    title="Cloud Computing"
    image="unsplash-Q1p7bh3SHj8"
    ratio={cardRatio}
  >
    We deploy our and our customer's applications to the cloud using
    infrastructure as a code techniques. We can redeploy an entire application
    stack with few clicks.
  </MediaCard>
);

const TradingApps: React.FC = () => {
  const sx = { pt: 4, pb: 2 };
  return (
    <WebPage
      title="Trading Applications"
      htmlTitle="Quantmind provides Algo Trading Software Solution"
      heroImage={image}
      description="We develop algo trading software solutions for hedge funds and proprietary trading firms"
      hero_dark={true}
      filter={0.8}
    >
      <Section maxWidth="md" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          sx={{ pb: 4 }}
        >
          At Quantmind, we specialize in developing cutting-edge trading and
          data analysis applications that empower businesses to make informed
          decisions and gain a competitive edge in today's dynamic market
          landscape. Our innovative solutions harness the power of Python and
          Rust, coupled with a robust cloud infrastructure utilizing Docker and
          Kubernetes, to deliver unparalleled performance, scalability, and
          reliability.
        </Typography>
      </Section>
      <Section maxWidth="md" title="Algorithmic Trading Applications" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Our trading applications are meticulously crafted to provide real-time
          insights and execution capabilities, enabling traders to respond
          swiftly to market fluctuations. Leveraging the power of Python and
          Rust, we develop performant algorithms and strategies that enhance
          trading outcomes and minimize risks.
        </Typography>
      </Section>
      <Section maxWidth="md" title="Data Analysis Applications" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Data is the cornerstone of effective decision-making. Our data
          analysis applications empower businesses to extract meaningful
          insights from complex datasets. By combining Python's rich ecosystem
          of data science libraries with Rust's low-level performance, we create
          applications that process, visualize, and interpret data with
          remarkable accuracy and efficiency.
        </Typography>
      </Section>
      <Section maxWidth="sm" title="Get Started" sx={sx}>
        <Box sx={{ display: "none" }}>
          <Process />
          <Cloud />
        </Box>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Are you ready to supercharge your trading strategies and data analysis
          capabilities? Contact us today to discuss how our Python and
          Rust-powered applications, coupled with our cloud infrastructure
          expertise, can transform your business.
        </Typography>
        <Typography align="center" sx={{ p: 2 }}>
          <ContactUs />
        </Typography>
      </Section>
    </WebPage>
  );
};

export default TradingApps;
