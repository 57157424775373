import { getBlock } from "@metablock/core";
import {
  createStores as createMetablockStores,
  MetablockStore,
} from "@metablock/store";

export interface QuantmindStore extends MetablockStore {}

export const createStores = (): QuantmindStore => {
  const block = getBlock();
  const stores = createMetablockStores(block.apiUrl, block.name);

  return {
    ...stores,
  };
};

const store: QuantmindStore = createStores();

export const useStores = (): QuantmindStore => store;
