import Grid from "@mui/material/Grid";
import MediaCard from "@quantmind/Components/Card";
import Section from "@quantmind/Components/Section";
import { TeamAugmentation } from "@quantmind/Pages/TeamAugmentation";
import { TradingApplications } from "@quantmind/Pages/TradingApplications";
import WebPage from "@quantmind/Views/WebPage";
import { cardElevation } from "@quantmind/theme";
import React from "react";

const image = "unsplash-RDolnHtjVCY";

export const Services: React.FC = () => (
  <MediaCard
    elevation={cardElevation}
    title="Our Services"
    image={image}
    url="/services"
    opacity={0.5}
  >
    We provide strategy and consulting services to businesses eager to
    understand, adopt, and succeed with new technology solutions. When you hire
    us, we become members of your development team with CTO-level expertise.
  </MediaCard>
);

const ServicesPage: React.FC = () => {
  return (
    <WebPage
      title="Our Services"
      htmlTitle="Quantmind services and solutions"
      heroImage={image}
      description="Quantmind Services and Solutions"
      hero_dark={true}
      filter={0.7}
    >
      <Section>
        <Grid container spacing={5}>
          <Grid item sm={12} md={6}>
            <TeamAugmentation />
          </Grid>
          <Grid item sm={12} md={6}>
            <TradingApplications />
          </Grid>
        </Grid>
      </Section>
    </WebPage>
  );
};

export default ServicesPage;
