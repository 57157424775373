import loadable from "@loadable/component";
import Grid from "@mui/material/Grid";
import MediaCard from "@quantmind/Components/Card";
import Section from "@quantmind/Components/Section";
import { TradingApplications } from "@quantmind/Pages/TradingApplications";
import WebPage from "@quantmind/Views/WebPage";
import { cardElevation, cardRatio } from "@quantmind/theme";
import React from "react";
import { Python } from "./Python";
import { Rust } from "./Rust";
import { WebUI } from "./WebUI";

const image = "unsplash-r_35Hp7mF4A";

export const Expertise: React.FC = () => (
  <MediaCard
    elevation={cardElevation}
    ratio={cardRatio}
    title="Expertise"
    image={image}
    url="/expertise"
    opacity={1}
  >
    We use great open source technologies to deliver our projects. 
  </MediaCard>
);

const Technologies = loadable(
  () =>
    import(/* webpackChunkName: "technologies" */ "../Components/Technologies")
);

const ExpertisePage: React.FC = () => {
  const grid = { xs: 12, sm: 6 };
  return (
    <WebPage
      title="Expertise"
      description="We use great open source technologies to deliver our projects"
      heroImage={image}
      hero_dark={true}
      filter={0.5}
    >
      <Technologies />
      <Section>
        <Grid container spacing={5}>
          <Grid item {...grid}>
            <Python />
          </Grid>
          <Grid item {...grid}>
            <Rust />
          </Grid>
          <Grid item {...grid}>
            <TradingApplications />
          </Grid>
          <Grid item {...grid}>
            <WebUI />
          </Grid>
        </Grid>
      </Section>
    </WebPage>
  );
};

export default ExpertisePage;
