import { CmsListLayout } from "@metablock/react";
import Section from "@quantmind/Components/Section";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";

const selectImage = (urls: string[], width: number | undefined): number => {
  if (!width) return 0;
  return 1;
};

const BlogList = (props: any) => {
  const { image, opacity = 1, theme, ...extra } = props;
  return (
    <WebPage
      title="Quantmind Blog"
      description="Writing about tech trading and finance"
      heroImage="unsplash-DpI-_wydgJM"
      hero_dark={true}
      filter={0.3}
    >
      <Section>
        <CmsListLayout
          {...extra}
          defaultUnsplash="O6N9RV2rzX8"
          selectImage={selectImage}
          imageWidth={200}
          imageHeight={150}
        />
      </Section>
    </WebPage>
  );
};

export default BlogList;
