import { NoSsr } from "@metablock/react";
import { useTheme } from "@mui/material/styles";
import Section from "@quantmind/Components/Section";
import Markdown from "@quantmind/Views/Notebook";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";
import { getHighlightStyle } from "../theme";

const BlogEntry = (props: any) => {
  const {
    hero_photo,
    hero_color,
    hero_photo_filter,
    hero_dark,
    highlight_style,
    image,
  } = props;
  const defaultTheme = useTheme();
  const highlightStyle =
    highlight_style || getHighlightStyle(defaultTheme.palette.mode);
  return (
    <WebPage
      heroImage={hero_photo}
      filter={hero_photo_filter}
      title={props.title}
      hero_dark={hero_dark}
      hero_color={hero_color}
      description={props.description}
      image={image}
    >
      <Section maxWidth="md">
        <NoSsr>
          <Markdown highlightStyle={highlightStyle} {...props} />
        </NoSsr>
      </Section>
    </WebPage>
  );
};

export default BlogEntry;
