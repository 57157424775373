import React from "react";
import dom from "react-dom/client";
import App from "./App";

const root = document.getElementById("__metablock") as HTMLElement;

if (root.hasChildNodes()) {
  dom.hydrateRoot(root, <App />);
} else {
  dom.createRoot(root).render(<App />);
}
