import { Link, List, Header as MetablockHeader } from "@metablock/react";
import Box from "@mui/material/Box";
import React from "react";
import logos from "./logos";

const links = [
  { to: "/services", text: "services" },
  { to: "/expertise", text: "expertise" },
  { to: "/about", text: "about" },
  { to: "/contact", text: "contact" },
  { to: "/oss", text: "opensource" },
  { to: "/blog", text: "blog" },
];

const HeaderLinks = ({ mobileOpen }: { mobileOpen: boolean }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <List
        direction={mobileOpen ? "vertical" : "horizontal"}
        align="right"
        underline="hover"
        items={links}
      />
    </Box>
  );
};

const Header = (props: any) => {
  const { logo = logos.color, logoChange, ...extra } = props;

  const Brand = (opts: any) => {
    const src = opts.colorChange && logoChange ? logoChange : logo;
    return (
      <Link to="/">
        <img src={src} height="60px" />
      </Link>
    );
  };
  return (
    <MetablockHeader
      fixed
      BrandComponent={Brand}
      RightLinks={HeaderLinks}
      paddingTop={1}
      paddingBottom={1}
      {...extra}
    />
  );
};

export default Header;
