import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React from "react";

interface Item {
  title: string;
  description: string;
  icon: React.ReactElement;
  sx?: any;
}

const AvatarList = ({ items }: { items: Item[] }) => {
  return (
    <List>
      {items.map((item: Item, index: number) => {
        const sx = { color: item?.sx?.color };
        return (
          <ListItem alignItems="flex-start" key={index} sx={item.sx}>
            <ListItemAvatar>
              <Avatar>{item.icon}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.title}
              secondary={item.description}
              secondaryTypographyProps={sx}
            ></ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

export default AvatarList;
