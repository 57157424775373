import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";

const Company = ({ sx }: { sx?: any }) => {
  return (
    <Box sx={sx}>
      <Typography
        variant="h6"
        color="textSecondary"
        component="p"
        align="center"
        paragraph
      >
        Quantmind is the trading name of Dynamic Quant Limited <br />
        Registered in England and Wales company number{" "}
        <Link
          target="_blank"
          rel="noopener"
          underline="hover"
          href="https://find-and-update.company-information.service.gov.uk/company/06746102"
        >
          6746102
        </Link>{" "}
        <br />
        VAT Registration Number GB972533015
      </Typography>
      <Typography
        variant="h6"
        color="textSecondary"
        component="p"
        align="center"
        paragraph
      >
        Suite 3 Grapes House
        <br />
        79a High Street <br />
        Esher, England, KT10 9QA
      </Typography>
    </Box>
  );
};

export default Company;
