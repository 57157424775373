import TailoredIcon from "@mui/icons-material/Assignment";
import VibrantIcon from "@mui/icons-material/Diversity2";
import GroupsIcon from "@mui/icons-material/Diversity3";
import VersatilityIcon from "@mui/icons-material/MiscellaneousServices";
import ReadabilityIcon from "@mui/icons-material/MusicVideo";
import ExpertIcon from "@mui/icons-material/School";
import { Grid, Typography } from "@mui/material";
import { lightBlue, yellow } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import AvatarList from "@quantmind/Components/AvatarList";
import MediaCard from "@quantmind/Components/Card";
import ContactUs from "@quantmind/Components/ContactUs";
import Section from "@quantmind/Components/Section";
import logos from "@quantmind/Components/logos";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";

const image = "unsplash-Q2S8rrSzz1U";

const whyUs = [
  {
    title: "Python Experts",
    icon: <ExpertIcon />,
    description:
      "Our team consists of experienced Python developers who are" +
      " passionate about pushing the boundaries of what's possible with the language.",
  },
  {
    title: "Tailored Solutions",
    icon: <TailoredIcon />,
    description:
      "We understand that every project is unique. Our consulting services are customized to fit your specific requirements and goals.",
  },
  {
    title: "Collaborative Approach",
    icon: <GroupsIcon />,
    description:
      "We believe in working closely with our clients throughout the" +
      " development process, ensuring that your vision is realized at every step.",
  },
];

const python = [
  {
    title: "Versatility",
    sx: {
      backgroundColor: yellow[500],
      color: lightBlue[900],
      mb: 1,
      borderRadius: 2,
    },
    icon: <VersatilityIcon />,
    description:
      "Python's broad range of libraries and frameworks makes it suitable for web applications, data analysis, scientific computing, artificial intelligence, and more",
  },
  {
    title: "Readability",
    sx: {
      backgroundColor: lightBlue[900],
      color: yellow[500],
      mb: 1,
      borderRadius: 2,
    },
    icon: <ReadabilityIcon />,
    description:
      "Python's clean and intuitive syntax enhances developer productivity and makes code maintenance a breeze.",
  },
  {
    title: "Vibrant Ecosystem",
    sx: {
      backgroundColor: yellow[500],
      color: lightBlue[900],
      mb: 1,
      borderRadius: 2,
    },
    icon: <VibrantIcon />,
    description:
      "The Python ecosystem boasts an extensive collection of libraries, tools, and resources that accelerate development across various domains",
  },
];

export const Python: React.FC = () => (
  <MediaCard align="center" title="Python Experts" image={image} url="/python">
    We are expert python developers crafting beautiful and robust code. Python
    is our preferred language when writing server-side software for web
    development, data streaming, data analysis and trading bots.
  </MediaCard>
);

const PythonPage: React.FC = () => {
  const sx = { pt: 4, pb: 2 };
  const theme = useTheme();
  const serviceTitleColor = theme.palette.warning.main;
  return (
    <WebPage
      title="We are Pythonista"
      htmlTitle="Quantmind Python Consulting Services"
      heroImage={image}
      image={logos.pythonCard}
      description="We develop machine learning, backend and data applications with Python and contribute to its opensource ecosystem"
      hero_dark={true}
      filter={0.5}
    >
      <Section maxWidth="md" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          sx={{ pb: 4 }}
        >
          Welcome to Quantmind, your partner in leveraging the power of Python
          to build dynamic and scalable solutions tailored to your unique needs.
          Our team of skilled Python developers and engineers is here to provide
          comprehensive consulting services that cover a wide range of domains,
          including web development, data analysis, machine learning, and
          automation.
        </Typography>
      </Section>
      <Section maxWidth="md" sx={sx} title="Why Python?">
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Python is a versatile and widely-used programming language known for
          its readability, ease of use, and a vast ecosystem of libraries and
          frameworks. Here's why Python could be the perfect solution for your
          project
          <AvatarList items={python} />
        </Typography>
      </Section>
      <Section maxWidth="md" title="Our Python Consulting Services" sx={sx}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              Data Analysis
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Uncover valuable insights from your data with our Python-based
              data analysis solutions. From data preprocessing and
              transformation to advanced analytics and visualization, we
              leverage Python's libraries to provide actionable insights.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              Machine Learning and AI
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Harness the potential of machine learning and artificial
              intelligence with our Python expertise. Develop predictive models,
              recommendation systems, natural language processing applications,
              and more using cutting-edge libraries like TensorFlow, PyTorch,
              and scikit-learn.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              Restful APIs
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Build powerful and efficient RESTful APIs with our Python
              expertise. Whether you're creating APIs for web applications,
              mobile apps, or integrations between systems, we have the
              experience to design and develop APIs that deliver seamless
              communication and data exchange.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              Automation
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Streamline your workflows and boost efficiency through Python
              automation solutions. Our consultants can help you create scripts
              and tools that automate repetitive tasks, saving time and reducing
              errors.
            </Typography>
          </Grid>
        </Grid>
      </Section>
      <Section maxWidth="md" title="Why Choose Quantmind?" sx={sx}>
        <AvatarList items={whyUs} />
      </Section>
      <Section maxWidth="sm" title="Get Started" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Are Ready to deploy the power and flexibility of Python for your next
          project? Contact us today to discuss your needs and learn more about
          how our Python consulting services can bring your ideas to life.
        </Typography>
        <Typography align="center" sx={{ p: 2 }}>
          <ContactUs />
        </Typography>
      </Section>
    </WebPage>
  );
};

export default PythonPage;
