import Button from "@mui/material/Button";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface CopyProps {
  title?: string;
  variant?: any;
  align?: any;
  rights?: React.ReactNode;
  color?: any;
}

const ContactUs = (props: CopyProps) => {
  const { title = "Contact Us", variant = "contained", color = "info" } = props;
  return (
    <Button
      component={RouterLink}
      to="/contact"
      variant={variant}
      color={color}
    >
      {title}
    </Button>
  );
};

export default ContactUs;
