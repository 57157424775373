import { Section as MetablockSection } from "@metablock/react";
import Typography from "@mui/material/Typography";
import React from "react";

const Section = (props: any) => {
  const { children, sx, title, ...extra } = props;
  const sxt = { pt: 8, pb: 8, ...sx };
  return (
    <MetablockSection sx={sxt} {...extra}>
      {title ? (
        <Typography
          color="textPrimary"
          variant="h5"
          component="h2"
          align="center"
          paragraph
        >
          {title}
        </Typography>
      ) : null}
      {children}
    </MetablockSection>
  );
};

export default Section;
