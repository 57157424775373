import { Image, useImage } from "@metablock/react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { cardElevation, cardRatio } from "@quantmind/theme";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const createSx = (ratio: number) => ({
  outer: {
    display: "block",
    height: "150px",
    width: "100%",
    paddingTop: `${ratio}%`,
    position: "relative",
    lineHeight: "150px",
  },
  inner: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    width: "100%",
    verticalAlign: "middle",
    objectFit: "cover",
  },
  image: {
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    verticalAlign: "middle",
    height: "auto",
  },
});

const selectImage = (urls: string[], width: number | undefined): number => {
  if (!width) return 0;
  return 1;
};

interface MediaProps {
  image: string;
  title: string;
  children: any;
  ratio?: number;
  align?: any;
  color?: string;
  variant?: any;
  opacity?: number;
  [key: string]: any;
}

const MediaCard = (props: MediaProps) => {
  const {
    image,
    title,
    children,
    ratio = cardRatio,
    elevation = cardElevation,
    align = "center",
    color = "textSecondary",
    variant = "body1",
    opacity = 1,
    url,
    ...extra
  } = props;
  const sx = createSx(ratio);
  const img = useImage(image);
  const content = (
    <>
      <Box sx={sx.outer}>
        <Image
          sx={sx.inner}
          urls={img.urls}
          opacity={opacity}
          fit="width"
          selectImage={selectImage}
          alt={title}
        />
      </Box>
      <CardContent>
        <Typography
          gutterBottom
          variant="h4"
          component="h2"
          paragraph
          align={align}
        >
          {title}
        </Typography>
        <Typography
          variant={variant}
          color={color}
          component="p"
          align="justify"
        >
          {children}
        </Typography>
      </CardContent>
    </>
  );

  return (
    <Card elevation={elevation} {...extra}>
      {url ? (
        <CardActionArea component={RouterLink} to={url}>
          {content}
        </CardActionArea>
      ) : (
        content
      )}
    </Card>
  );
};
export default MediaCard;
