import ConcurrencyIcon from "@mui/icons-material/Apps";
import TailoredIcon from "@mui/icons-material/Assignment";
import GroupsIcon from "@mui/icons-material/Diversity3";
import LoveIcon from "@mui/icons-material/Favorite";
import MemoryIcon from "@mui/icons-material/Memory";
import ExpertIcon from "@mui/icons-material/School";
import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Grid, Typography } from "@mui/material";
import { cyan, deepOrange, green, orange } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import AvatarList from "@quantmind/Components/AvatarList";
import MediaCard from "@quantmind/Components/Card";
import ContactUs from "@quantmind/Components/ContactUs";
import Section from "@quantmind/Components/Section";
import logos from "@quantmind/Components/logos";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";

const image = "unsplash-PEy4qZCLXss";
const whyUs = [
  {
    title: "Rust Experts",
    icon: <ExpertIcon />,
    description:
      "Our team consists of experienced Rust developers who are" +
      " passionate about pushing the boundaries of what's possible with the language.",
  },
  {
    title: "Tailored Solutions",
    icon: <TailoredIcon />,
    description:
      "We understand that every project is unique. Our consulting services are customized to fit your specific requirements and goals.",
  },
  {
    title: "Collaborative Approach",
    icon: <GroupsIcon />,
    description:
      "We believe in working closely with our clients throughout the" +
      " development process, ensuring that your vision is realized at every step.",
  },
];

const rust = [
  {
    title: "Memory Safety",
    sx: { backgroundColor: green[900], mb: 1, borderRadius: 2 },
    icon: <MemoryIcon />,
    description:
      "Rust's strict ownership and borrowing system eliminates common" +
      " memory-related bugs like null pointer dereferences and data races " +
      " resulting in more stable and secure applications.",
  },
  {
    title: "Performance",
    sx: { backgroundColor: cyan[900], mb: 1, borderRadius: 2 },
    icon: <SpeedIcon />,
    description:
      "Rust's zero-cost abstractions and fine-grained control" +
      " over system resources allow us to create applications that are not" +
      " only safe but also highly performant.",
  },
  {
    title: "Concurrency",
    sx: { backgroundColor: deepOrange[900], borderRadius: 2 },
    icon: <ConcurrencyIcon />,
    description:
      "Rust's concurrency model enables efficient multi-threading and asynchronous" +
      " programming, making it ideal for applications that need to handle" +
      " many tasks simultaneously.",
  },
];

export const Rust: React.FC = () => (
  <MediaCard title="Rust Experts" image={image} url="/rust">
    We bring a deep understanding of the Rust programming language and its
    ecosystem to every project. Our strong communication and collaboration
    skills make us valuable members of any development team. Trust us to bring
    our Rust expertise to your project.
  </MediaCard>
);

const About: React.FC = () => {
  const sx = { pt: 4, pb: 2 };
  const theme = useTheme();
  const serviceTitleColor = theme.palette.warning.main;
  return (
    <WebPage
      title={
        <Box>
          We <LoveIcon fontSize="large" sx={{ color: orange[900] }} /> Rust
        </Box>
      }
      htmlTitle="Quantmind provides Rust consulting services"
      heroImage={image}
      image={logos.rustCard}
      description="We develop trading applications with Rust and contribute to its opensource ecosystem"
      hero_dark={true}
      filter={0.5}
      titleSx={{ p: 2, borderRadius: 5, backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <Section maxWidth="md" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          sx={{ pb: 4 }}
        >
          At Quantmind, we are passionate about harnessing the power of Rust to
          build robust, high-performance applications that stand the test of
          time. Our team of experienced Rust developers and engineers is here to
          provide comprehensive consulting services tailored to your specific
          needs. Whether you're looking to create cutting-edge back-end
          services, perform data analysis, or develop high frequency trading
          bots, we've got you covered.
        </Typography>
      </Section>
      <Section maxWidth="md" sx={sx} title="Why Rust?">
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Rust is a modern programming language that emphasizes safety,
          performance, and concurrency. Its unique combination of features makes
          it an excellent choice for a wide range of applications, from
          system-level programming to web development and beyond. Here's why
          Rust could be the perfect solution for your project
        </Typography>
        <AvatarList items={rust} />
      </Section>
      <Section maxWidth="md" title="Our Rust Consulting Services" sx={sx}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              Is Rust right for your project?
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Rust is a versatile language with unique strengths, but it's
              important to determine if it aligns with your project's
              requirements. Our experienced consultants can help you assess
              whether Rust is the right choice for your specific needs.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              Back-End Services
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Harness the power of Rust to build scalable and efficient back-end
              services. Whether you're building a RESTful API, a microservices
              architecture, or a custom web server, our team has the expertise
              to design and develop reliable back-end solutions that meet your
              business objectives.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              Data Analysis
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Data Analysis Unlock insights from your data with Rust-powered
              data analysis solutions. From data collection and transformation
              to statistical analysis and visualization, we leverage Rust's
              performance capabilities to provide you with fast and accurate
              data-driven insights.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              color={serviceTitleColor}
              variant="h6"
              component="h3"
              align="center"
              paragraph
            >
              High Frequency Trading Bots
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              component="p"
              align="left"
              paragraph
            >
              Navigate the complexities of financial markets with custom
              Rust-based trading bots. Our team can develop algorithmic trading
              solutions that execute trades with precision, taking advantage of
              Rust's speed and reliability to ensure optimal performance in
              time-sensitive trading environments.
            </Typography>
          </Grid>
        </Grid>
      </Section>
      <Section maxWidth="md" title="Why Choose Quantmind?" sx={sx}>
        <AvatarList items={whyUs} />
      </Section>
      <Section maxWidth="sm" title="Get Started" sx={sx}>
        <Typography
          color="textSecondary"
          variant="body1"
          component="p"
          align="left"
          paragraph
        >
          Are Ready to deploy the power of Rust for your next project? Contact
          us today to discuss your needs and learn more about how our Rust
          consulting services can bring your ideas to life.
        </Typography>
        <Typography align="center" sx={{ p: 2 }}>
          <ContactUs />
        </Typography>
      </Section>
    </WebPage>
  );
};

export default About;
