import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Company from "@quantmind/Components/Company";
import Section from "@quantmind/Components/Section";
import Social from "@quantmind/Components/Social";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";

const Contact: React.FC = () => {
  return (
    <WebPage
      title="Contact"
      heroImage="unsplash--0xCCPIbl3M"
      description="We’d love to hear from you!"
      hero_dark={true}
      filter={0.7}
    >
      <Section maxWidth="md">
        <Typography
          color="textPrimary"
          variant="h4"
          component="h4"
          align="center"
          paragraph
        >
          Get in Touch
        </Typography>
        <Typography
          color="textSecondary"
          variant="h5"
          component="p"
          align="center"
          paragraph
        >
          Whether you would like to learn more about us or discuss a business
          opportunity
        </Typography>
        <Typography
          color="textSecondary"
          variant="h4"
          component="p"
          align="center"
          paragraph
        >
          <Link color="inherit" href="mailto:info@quantmind.com">
            info@quantmind.com
          </Link>
          <br />
        </Typography>
        <Typography
          color="textSecondary"
          variant="h4"
          component="p"
          align="center"
          paragraph
        >
          <Social fontSize="inherit" />
        </Typography>
        <Company />
      </Section>
    </WebPage>
  );
};

export default Contact;
