import { blue, grey } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

export const options = {
  drawerWidth: 240,
};

export const fontFamilyMono = "'Roboto Mono', monospace";
export const cardElevation = 1;
export const cardRatio = 40;
const light_primary = blue;
const typography = {
  fontSize: 18,
  fontFamily: "'Roboto', sans-serif",
  caption: {
    fontSize: 12,
  },
};

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        minWidth: "300px",
      },
    },
  },
};

export const lightTheme = responsiveFontSizes(
  createTheme({
    components,
    typography,
    palette: {
      mode: "light",
      primary: {
        light: light_primary["100"],
        main: light_primary["600"],
        dark: light_primary["900"],
      },
      secondary: {
        light: grey["50"],
        main: grey["100"],
        dark: grey["200"],
      },
      neutral: {
        main: "#fff",
      },
    },
  })
);

export const darkTheme = responsiveFontSizes(
  createTheme({
    typography,
    palette: {
      mode: "dark",
      secondary: {
        light: grey["700"],
        main: grey["800"],
        dark: grey["900"],
      },
      neutral: {
        main: grey["900"],
      },
    },
  })
);

export const getHighlightStyle = (mode: string) => {
  return mode === "light" ? "github" : "monokai-sublime";
};

export const getCodeBackground = (mode: string) => {
  return mode === "light" ? "#fff" : "#171717";
};
