import { Link } from "@metablock/react";
import Typography from "@mui/material/Typography";
import React from "react";

interface CopyProps {
  title: string;
  variant?: any;
  align?: any;
  rights?: React.ReactNode;
  linkColor?: string;
}

const Copyright = (props: CopyProps) => {
  const {
    linkColor = "inherit",
    variant = "body2",
    align = "center",
    title,
  } = props;
  return (
    <Typography
      component="div"
      variant={variant}
      color="textSecondary"
      align={align}
    >
      {"© "}
      {new Date().getFullYear()}{" "}
      <Link color={linkColor} to="/" underline="hover">
        {title}
      </Link>
      {" - "}
      <Link color={linkColor} to="/privacy" underline="hover">
        privacy policy
      </Link>
    </Typography>
  );
};

export default Copyright;
