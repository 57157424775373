import Typography from "@mui/material/Typography";
import Company from "@quantmind/Components/Company";
import ContactUs from "@quantmind/Components/ContactUs";
import Section from "@quantmind/Components/Section";
import Timeline from "@quantmind/Components/Timeline";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";

const About: React.FC = () => {
  return (
    <WebPage
      title="About Us"
      htmlTitle="About Quantmind and our team of distributed software engineers"
      heroImage="unsplash-U2eUlPEKIgU"
      heroMaxHeight="700px"
      description="Quantmind is a distributed team of quants and software engineers"
      hero_dark={true}
      filter={0.2}
      titleSx={{ p: 4, borderRadius: 5, backgroundColor: "rgba(0,0,0,0.7)" }}
    >
      <Section maxWidth="md">
        <Typography
          color="textPrimary"
          variant="h4"
          component="h4"
          align="center"
          paragraph
        >
          Over a decade of success
        </Typography>
        <Typography
          color="textSecondary"
          variant="h5"
          component="p"
          align="center"
          paragraph
        >
          We are a network of technologists and scientists
          <br />
          spreading across Europe and beyond.
          <br />
          <br />
          Quantminders are inquisitive, talented problem solvers dedicated to
          the pursuit of digital excellence.
        </Typography>
        <Timeline />
        <Typography align="center" sx={{ pt: 2 }}>
          <ContactUs title="Find out what we can do for you" />
        </Typography>
        <Company sx={{ mt: 10 }} />
      </Section>
    </WebPage>
  );
};

export default About;
