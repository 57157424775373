import { Link, Section } from "@metablock/react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { Theme, useTheme } from "@mui/material/styles";
import React from "react";
import Copyright from "./Copyright";
import Social from "./Social";

const columns = [
  {
    name: "Company",
    links: [
      {
        label: "About",
        to: "/about",
      },
      {
        label: "Services",
        to: "/services",
      },
      {
        label: "Expertise",
        to: "/expertise",
      },
    ],
  },
  {
    name: "Resources",
    links: [
      {
        label: "Blog",
        to: "/blog",
      },
      {
        label: "Python",
        to: "/python",
      },
      {
        label: "Rust",
        to: "/rust",
      },
    ],
  },
  {
    name: "Connect",
    links: [
      {
        label: "Contact us",
        to: "/contact",
      },
      {
        label: "Open Source Software",
        to: "/oss",
      },
    ],
  },
];

const createSx = (theme: Theme) => {
  const p = theme.palette;
  const dark = p.mode === "dark";

  return {
    root: {
      backgroundColor: dark ? p.secondary.dark : p.secondary.main,
      borderTop: `1px solid ${dark ? p.secondary.main : p.secondary.dark}`,
      left: 0,
      right: 0,
      position: "absolute",
    },
    container: {
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(15),
      display: "flex",
    },
    icon: {
      width: 48,
      height: 48,
      display: "flex",
    },
    icons: {},
    iconsWrapper: {
      height: 120,
    },
    list: {},
    listItem: {
      pb: 1,
      pt: 0,
    },
  };
};

const Footer: React.FC = () => {
  const sx = createSx(useTheme());
  const nc = 12 / columns.length;
  return (
    <Typography
      component="footer"
      variant="body2"
      color="textSecondary"
      sx={sx.root}
    >
      <Section component="div" sx={{ pt: 4, pb: 4 }}>
        <Container maxWidth="sm" disableGutters>
          <Grid container spacing={4}>
            {columns.map((column) => (
              <Grid item xs={12} sm={nc} key={column.name}>
                <Grid container>
                  <Grid item xs={4} sm={12} md={12}>
                    <Typography component="h5" fontWeight={700} paragraph>
                      {column.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={12} md={12}>
                    <Typography variant="body2">
                      <List disablePadding>
                        {column.links.map((entry: any, index: number) => (
                          <ListItem sx={sx.listItem} key={index} disableGutters>
                            <Link
                              color="inherit"
                              to={entry.to}
                              underline="hover"
                            >
                              {entry.label}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
      <Section component="div" sx={{ pt: 4, pb: 4 }}>
        <Typography component="div" color="textSecondary" align="center">
          <Social fontSize="large" />
        </Typography>
      </Section>
      <Section component="div" sx={{ pt: 2, pb: 2 }}>
        <Copyright title="quantmind" variant="caption" />
      </Section>
    </Typography>
  );
};

export default Footer;
