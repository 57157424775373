import { getBlock } from "@metablock/core";
import logos from "@quantmind/Components/logos";
import WebPage from "@quantmind/Views/WebPage";
import React from "react";
import ProductSection from "./ProductSection";

const Home: React.FC = () => {
  const block = getBlock();
  const title = <img src={logos.light} height="auto" width="100%" />;

  return (
    <WebPage
      title={title}
      logo={null}
      htmlTitle={block.title}
      description={block.description}
      prefix={false}
      filter={0.5}
      heroImage="unsplash-_XVE6pKGiLE"
      heroMaxHeight="1000px"
      titleVariant="h2"
      descriptionVariant="h5"
      changeColorHeight={200}
      hero_dark
    >
      <ProductSection />
    </WebPage>
  );
};

export default Home;
