import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { blue, deepOrange, teal, yellow } from "@mui/material/colors";
import { Octokit } from "@octokit/rest";
import Section from "@quantmind/Components/Section";
import WebPage from "@quantmind/Views/WebPage";
import { useStores } from "@quantmind/stores";
import React from "react";
import { useAsync } from "react-use";

const octokit = new Octokit();

const projects = [
  "quantflow",
  "aio-openapi",
  "ccy",
  "kollector",
  "aio-kong",
  "metablock-js",
  "metablock-py",
  "metablock-action",
  "awesome-data-science-viz",
  "awesome-open-finance",
];
const projectSet = new Set(projects);

const sx = {
  other: {
    backgroundColor: teal[200],
    color: blue[900],
  },
  python: {
    backgroundColor: yellow[600],
    color: blue[800],
  },
  rust: {
    backgroundColor: deepOrange[800],
    color: blue[50],
  },
  typescript: {
    color: blue[50],
    backgroundColor: blue[600],
  },
  javascript: {
    color: blue[50],
    backgroundColor: blue[600],
  },
};

const responseData = (data: any[]) => {
  return data.map((d: any) => ({
    html_url: d.html_url,
    name: d.name,
    description: d.description,
    stars: d.stargazers_count,
    language: d.language,
    archived: d.archived,
  }));
};

const OSS: React.FC = () => {
  const { metablock } = useStores();
  const { value, loading } = useAsync(async () => {
    return await metablock.cache.getSet(
      "oss-repos",
      async () => {
        const response = await octokit.paginate(octokit.repos.listForOrg, {
          org: "quantmind",
          type: "public",
        });
        return responseData(response);
      },
      60 * 60
    );
  });
  return (
    <WebPage
      title="Opensource"
      heroImage="unsplash-ieic5Tq8YMk"
      description="Quantmind uses and contributes to opensource software"
      filter={0.7}
      hero_dark={true}
    >
      <Section maxWidth="md" sx={{ pb: 0 }}>
        <Typography
          color="textSecondary"
          variant="h5"
          component="p"
          align="justify"
          paragraph
        >
          Because Open Source plays a major part in how we build our products,
          we see it as a matter of course to give the same effort back to our
          community by creating valuable, free and easy-to-use software.
        </Typography>
      </Section>
      {loading ? null : <Repos repositories={value || []} />}
    </WebPage>
  );
};

const Repos = ({ repositories }: { repositories: Array<any> }) => {
  const byName: Record<string, any> = repositories.reduce((o: any, r: any) => {
    o[r.name] = r;
    return o;
  }, {});
  const premium: any[] = projects
    .map((name: string) => byName[name])
    .filter((repo: any) => repo);
  const secondary: any[] = repositories.filter(
    (repo: any) => !projectSet.has(repo.name) && !repo.archived
  );
  const archived: any[] = repositories.filter(
    (repo: any) => !projectSet.has(repo.name) && repo.archived
  );
  return (
    <Section>
      <Box pb={4}>
        <Typography
          color="textPrimary"
          variant="h4"
          component="h4"
          align="center"
          paragraph
        >
          Sponsored Projects
        </Typography>
        <Grid container spacing={2}>
          {premium.map((repo: any, index: number) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
              <Repo {...repo} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box pb={4}>
        <Typography
          color="textPrimary"
          variant="h4"
          component="h4"
          align="center"
          paragraph
        >
          Other Projects
        </Typography>
        <Grid container spacing={2}>
          {secondary.map((repo: any, index: number) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
              <Repo {...repo} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box pb={4}>
        <Typography
          color="textPrimary"
          variant="h4"
          component="h4"
          align="center"
          paragraph
        >
          Archived Projects (no longer maintained)
        </Typography>
        <Grid container spacing={2}>
          {archived.map((repo: any, index: number) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
              <Repo {...repo} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Section>
  );
};

const Repo = (props: any) => {
  const { name, description, language } = props;
  const lang = language || "Markdown";
  const key = lang.toLowerCase();
  // @ts-ignore
  const sxr = sx[key] || sx.other;
  const color: any = { fontWeight: 600, ...sxr };
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography color="textPrimary" variant="h5" paragraph>
          <Link
            color="inherit"
            href={props.html_url}
            target="_blank"
            rel="noopener"
            underline="none"
          >
            {name}
          </Link>
        </Typography>
        <Typography color="textSecondary" variant="body1" paragraph>
          {description}
        </Typography>
        <Chip label={lang} sx={color} />
      </CardContent>
    </Card>
  );
};

export default OSS;
