import { assetUrl } from "@metablock/core";

export default {
  light: assetUrl("quantmind-logo-white.svg"),
  dark: assetUrl("quantmind-logo-black.svg"),
  color: assetUrl("quantmind-logo.svg"),
  card: assetUrl("quantmind-card-1280x720.png"),
  rustCard: assetUrl("quantmind-rust.png"),
  pythonCard: assetUrl("quantmind-python.png"),
};
