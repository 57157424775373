import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import MuiLink from "@mui/material/Link";
import React from "react";

const social = [
  {
    href: "https://twitter.com/quantmind",
    Icon: TwitterIcon,
  },
  {
    href: "https://www.linkedin.com/company/dynamic-quant-limited/",
    Icon: LinkedInIcon,
  },
  {
    href: "https://github.com/quantmind",
    Icon: GitHubIcon,
  },
];

const Social = (props: any) => {
  const { color = "inherit", fontSize = "small", spacing = 1 } = props;
  return (
    <>
      {social.map((s: any, index: number) => {
        const { Icon, href } = s;
        return (
          <MuiLink
            sx={{ mr: spacing }}
            color={color}
            target="_blank"
            rel="noopener"
            key={index}
            href={href}
          >
            <Icon fontSize={fontSize} />
          </MuiLink>
        );
      })}
    </>
  );
};

export default Social;
