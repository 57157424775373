import { Notebook, createNotebook } from "@metablock/notebook";
import { isSsr, useWindowSize } from "@metablock/react";
import Box from "@mui/material/Box";
import { Theme, useTheme } from "@mui/material/styles";
import { fontFamilyMono, getCodeBackground } from "@quantmind/theme";
import React from "react";
export {};

declare global {
  interface Window {
    notebook: Notebook;
  }
}

const nodeBookStyle = (theme: Theme, props: any): any => {
  const { anchor = "primary" } = props;
  const palette: any = theme.palette;
  return {
    "& p": {
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: 400,
    },
    "& ul": {
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: 400,
    },
    "& code": {
      fontSize: theme.typography.body1.fontSize,
      fontFamily: fontFamilyMono,
      fontWeight: 500,
    },
    "& a": {
      textDecoration: "none",
      color: palette[anchor].main,
    },
    "& pre": {
      overflowX: "auto",
      fontSize: theme.typography.body1.fontSize,
      backgroundColor: getCodeBackground(theme.palette.mode),
      margin: 0,
      marginBottom: 2,
      padding: 1,
      borderRadius: 1,
    },
    "& blockquote": {
      fontStyle: "italic",
      color: palette.text.secondary,
    },
    "& div.cm-editor": {
      backgroundColor: getCodeBackground(theme.palette.mode),
      borderRadius: 1,
      "&.cm-focused": {
        outline: "2px solid cyan",
      },
    },
  };
};

const getNotebook = (theme: string): Notebook => {
  const notebook = createNotebook(theme);
  // notebook.options.highlightStyle = getHighlightStyle(theme);
  return notebook;
};


const Book = (props: any) => {
  const { body, ...extra } = props;
  const theme = useTheme();
  const noteRef = React.useRef<Notebook>(getNotebook(theme.palette.mode));
  const ref = React.useRef<HTMLDivElement>();
  const notebook = noteRef.current;
  // make sure to re-render when offsetWidth change
  useWindowSize(() => {
    if (!ref.current) return;
    return ref.current.offsetWidth;
  });
  const setRef = async (element: HTMLDivElement) => {
    ref.current = element;
    if (element)
      await notebook.render(body, element, { ...extra, isSsr: isSsr() });
  };
  return <Box sx={nodeBookStyle(theme, extra)} ref={setRef} />;
};

export default Book;
